import CssIcon from 'components/icons/CssIcon'
import ExpressIcon from 'components/icons/ExpressIcon'
import GitlabIcon from 'components/icons/GitlabIcon'
import GoogleIcon from 'components/icons/GoogleIcon'
import TypescriptIcon from 'components/icons/TypescriptIcon'
import WebpackIcon from 'components/icons/WebpackIcon'

export const CONTENT = [
  {
    icon: null,
    heading: 'React starter kit',
    downloadLink: true
  },
  {
    icon: WebpackIcon,
    heading: 'Webpack & Eslint',
    p: 'Basic webpack dev/production configuration. And Eslint to write prettier code',
    downloadLink: false
  },
  {
    icon: ExpressIcon,
    heading: 'Express',
    p: 'Simple server to handle API calls',
    downloadLink: false
  },
  {
    icon: TypescriptIcon,
    heading: 'Typescript',
    p: 'Write modern, type driven code',
    downloadLink: false
  },
  {
    icon: CssIcon,
    heading: 'CSS modules & Stylelint',
    p: 'Avoid namespace collisions for CSS classes. And write prettier styles.',
    downloadLink: false
  },
  {
    icon: GoogleIcon,
    heading: 'Google Spreadsheet',
    p: 'Use Google sheet API as basic "database"',
    downloadLink: false
  },
  {
    icon: GitlabIcon,
    heading: 'Gitlab/Heroku integration',
    p: 'Basic pipeline configuration to easily deploy to Heroku',
    downloadLink: false
  }
]
