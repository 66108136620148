import clsx from 'clsx'
import React, { useState, CSSProperties as CSSProp } from 'react'
import CloneProjectLink from 'components/clone-link'
import { CONTENT } from 'components/shared/texts'
import css from './container.module.scss'

const Container = () => {
  const [activeItem, setActiveItem] = useState(0)
  const handleEnter = (e: React.MouseEvent) => {
    setActiveItem(parseInt(e.currentTarget.id) || 0)
  }
  const handleLeave = () => setActiveItem(0)

  return (
    <div className={css.container}>
      <ul className={css.list} style={{ '--item-total': '6' } as CSSProp}>
        {CONTENT.map((item, i) => {
          if (!item.icon) return null
          const { icon: Icon } = item
          return (
            <li
          key={i}
          className={css.item}
          style={{ '--item-count': `${i}` } as CSSProp}
          id={`${i}`}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          >
              <a className={css.circle}>
                <Icon className={css.icon}/>
              </a>
            </li>
          )
        })
        }
        <li className={css.mainCircle}>
          <div className={clsx(css.mainCircleContent, activeItem === 0 && css.active)}>
            <h1>{CONTENT[activeItem].heading}</h1>
            {CONTENT[activeItem].p && <p>{CONTENT[activeItem].p}</p>}
            {CONTENT[activeItem]?.downloadLink && <CloneProjectLink/>}
          </div>
        </li>
      </ul>

    </div>
  )
}

export default Container
