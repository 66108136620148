// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Rwm0l{display:flex;width:100%;margin-top:25px;justify-content:center}.container_Rwm0l a{text-decoration:none;color:#7e8c9b;font-weight:700}.container_Rwm0l:hover a{color:#2b67ac}.container_Rwm0l:hover svg{transform:rotate(360deg)}.icon_7fmqq{width:25px;height:25px;margin-right:10px;vertical-align:bottom;transition:0.7s ease-in-out}\n", "",{"version":3,"sources":["webpack://./src/components/clone-link/cloneProject.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA,iBACE,YAAa,CACb,UAAW,CACX,eAAgB,CAChB,sBAAuB,CAJzB,mBAOI,oBAAqB,CACrB,aCNmB,CDOnB,eAAgB,CATpB,yBAcM,aCdgB,CDAtB,2BAkBM,wBAAyB,CAC1B,YAKH,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,qBAAsB,CACtB,2BAA4B","sourcesContent":["@import \"../../variables.scss\";\n\n.container {\n  display: flex;\n  width: 100%;\n  margin-top: 25px;\n  justify-content: center;\n\n  a {\n    text-decoration: none;\n    color: $secondary-grey;\n    font-weight: 700;\n  }\n\n  &:hover {\n    a {\n      color: $tertiary-blue;\n    }\n\n    svg {\n      transform: rotate(360deg);\n    }\n  }\n}\n\n.icon {\n  width: 25px;\n  height: 25px;\n  margin-right: 10px;\n  vertical-align: bottom;\n  transition: 0.7s ease-in-out;\n}\n","$primary-blue:#064997;\n$secondary-blue:#105ba7;\n$tertiary-blue:#2b67ac;\n$primary-grey:#687583;\n$secondary-grey:#7e8c9b;\n$tertiary-grey:#6d757e;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Rwm0l",
	"icon": "icon_7fmqq"
};
export default ___CSS_LOADER_EXPORT___;
