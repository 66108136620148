import React from 'react'
import './app.scss'
import InfoDesktop from 'components/info-desktop'
import InfoMobile from 'components/info-mobile'

const Home = () => {
  return (
    <div className="container">
      <div className="header">
        <InfoDesktop/>
        <InfoMobile/>
      </div>
    </div>
  )
}

export default Home
