import React from 'react'
import GitlabIcon from 'components/icons/GitlabIcon'
import css from './cloneProject.module.scss'

const CloneProjectLink = () => {
  const url = 'https://gitlab.com/kubactech/react-ts-starter'
  return (
    <div className={css.container}>
      <a href={url}><GitlabIcon className={css.icon} />Clone me on Gitlab</a>
    </div>
  )
}

export default CloneProjectLink
