import clsx from 'clsx'
import React, { useState, FC } from 'react'
import css from './container.module.scss'

interface Props {
    heading:string
    p: string
    icon: React.FunctionComponent<{className:string}>
}

const Item: FC<Props> = ({ heading, p, icon: Icon }) => {
  const [isOpened, setOpened] = useState(false)
  const handleClick = () => setOpened(prevState => !prevState)
  const buttonSymbol = isOpened ? '-' : '+'
  return (
    <li className={clsx(css.item, isOpened && css.itemOpened)} onClick={handleClick}>
      <div className={css.itemHeader}>
        <Icon className={css.icon}/>
        <h2>{heading}</h2>
        <button
        className={clsx(css.button, isOpened && css.buttonOpened)}>
          {buttonSymbol}
        </button>
      </div>
      {isOpened &&
        <div className={css.itemText}>
          <p>{p}</p>
        </div>}
    </li>
  )
}

export default Item
