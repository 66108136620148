import React from 'react'
import CloneProjectLink from 'components/clone-link'
import { CONTENT } from 'components/shared/texts'
import Item from './Item'
import css from './container.module.scss'

const Container = () => {
  return (
    <div className={css.container}>
      <div className={css.header}>
        <h1>{CONTENT[0].heading}</h1>
        <CloneProjectLink/>
      </div>
      <div className={css.texts}>
        <ul>
          {CONTENT.map((item, i) => {
            if (!item.icon) return null
            return <Item key={i} {...item} />
          })}
        </ul>
      </div>
    </div>
  )
}

export default Container
